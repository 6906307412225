import {groq} from 'next-sanity';

import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {HeroWithBgImageSchema} from './schema';

export const HERO_WITH_BG_IMAGE_FRAGMENT = groq`
	...,
	introduction[]{
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

// TODO: Should add the resolved portable text field to this type:
export type HeroWithBgImageFragment = HeroWithBgImageSchema;
