import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	heading?: string;
};

export const textHeadingPortableTextOverrides: PortableTextOverrides = {};

export const TextHeading: FC<Props> = ({heading, children}) => {
	return (
		<Container className={clsx('py-24', 'md:py-40')}>
			<div className={clsx('max-w-3xl')}>
				<Heading spacing>{heading}</Heading>
				{children}
			</div>
		</Container>
	);
};
