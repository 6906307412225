import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Person} from '../../shared/types';
import {DOCUMENT_TYPE_PERSON} from '../constants';
import {
	PERSON_IN_CARD_FRAGMENT,
	personFromPersonInCardFragment,
	type PersonInCardFragment,
} from '../query';

import type {PersonListAllSchema} from './schema';

export const PERSON_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "nameAtoZ" => {
		"persons": *[_type == "${DOCUMENT_TYPE_PERSON}"] | order(lower(name) asc) {
			${PERSON_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "nameZtoA" => {
		"persons": *[_type == "${DOCUMENT_TYPE_PERSON}"] | order(lower(name) desc) {
			${PERSON_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"persons": *[_type == "${DOCUMENT_TYPE_PERSON}"] | order(lower(name) asc) {
			${PERSON_IN_CARD_FRAGMENT}
		}
	}
`;

export type PersonListAllFragment = PersonListAllSchema & {
	persons: PersonInCardFragment[] | null;
};

export const personListFromPersonListAllSection = (
	section: PersonListAllFragment,
): KeyedArray<Person> => {
	if (!section.persons) {
		return [];
	}

	return section.persons.map((person) => {
		return {
			_key: person?._id ?? '',
			...personFromPersonInCardFragment(person),
		};
	});
};
