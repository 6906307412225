interface Props {
	color?: string;
	className?: string;
	size?: number;
}

export const FullScreenExitIcon = ({color = '#000000', className, size}: Props) => {
	return (
		<svg
			width={size ? `${size}` : '1em'}
			height={size ? `${size}` : '1em'}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				fill={color}
				d="M8 16H6q-.425 0-.713-.288T5 15q0-.425.288-.713T6 14h3q.425 0 .713.288T10 15v3q0 .425-.288.713T9 19q-.425 0-.713-.288T8 18v-2Zm8 0v2q0 .425-.288.713T15 19q-.425 0-.713-.288T14 18v-3q0-.425.288-.713T15 14h3q.425 0 .713.288T19 15q0 .425-.288.713T18 16h-2ZM8 8V6q0-.425.288-.713T9 5q.425 0 .713.288T10 6v3q0 .425-.288.713T9 10H6q-.425 0-.713-.288T5 9q0-.425.288-.713T6 8h2Zm8 0h2q.425 0 .713.288T19 9q0 .425-.288.713T18 10h-3q-.425 0-.713-.288T14 9V6q0-.425.288-.713T15 5q.425 0 .713.288T16 6v2Z"
			></path>
		</svg>
	);
};
