import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	heading?: string;
	imageWeb?: ImageWeb;
};

export const heroWithSideImagePortableTextOverrides: PortableTextOverrides = {
	callToActionLine: 'justify-center lg:justify-start',
};

export const HeroWithSideImage: FC<Props> = ({heading, imageWeb, children}) => {
	return (
		<div className={clsx('lg:relative')}>
			<Container className="static">
				<div
					className={clsx(
						'mx-auto',
						'w-full',
						'max-w-7xl',
						'pb-20',
						'pt-16',
						'text-center',
						'lg:py-48',
						'lg:text-left',
					)}
				>
					<div className={clsx('lg:w-1/2', 'xl:pr-16', 'lg:pr-4')}>
						<Heading>{heading}</Heading>
						<div
							className={clsx(
								'mx-auto',
								'mt-3',
								'max-w-md',
								'md:mt-5',
								'md:max-w-3xl',
							)}
						>
							{children}
						</div>
					</div>
				</div>
				<div
					className={clsx(
						'relative',
						'h-64',
						'w-full',
						'sm:h-72',
						'md:h-96',
						'lg:absolute',
						'lg:inset-y-0',
						'lg:right-0',
						'lg:h-full',
						'lg:w-1/2',
						'max-lg:!col-span-full',
					)}
				>
					{imageWeb && (
						<ImageFill
							priority // Images in page heads are usually part of the LCP
							src={imageWeb.image.src}
							width={imageWeb.image.width}
							height={imageWeb.image.height}
							hotspot={imageWeb.image.hotspot}
							alt={imageWeb.altText ?? ''}
						/>
					)}
				</div>
			</Container>
		</div>
	);
};
