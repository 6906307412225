import type {FC} from 'react';
import React from 'react';

import type {ContainerSpacing} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';

import {Form} from '../form';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
}

export const ContactFormSection: FC<Props> = ({spacingBottom, spacingTop}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<Form />
		</Container>
	);
};
