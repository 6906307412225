import type {FC} from 'react';
import clsx from 'clsx';

import {ImageBlock} from '@/modules/foundation/components/image/ImageBlock';
import {Link} from '@/modules/foundation/components/link';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';

import type {Person} from '../../shared/types';

export interface PersonCardProps {
	person: Person;
}

export const PersonCard: FC<PersonCardProps> = ({person}) => {
	const {name, image, phone, email, role} = person;

	return (
		<div className={clsx('relative')}>
			<ImageBlock
				alt={image.altText ?? ''}
				height={image.image.height}
				width={image.image.width}
				src={image.image.src}
			/>

			<Paragraph>{name}</Paragraph>

			{role && <Paragraph>{role}</Paragraph>}

			{phone && <Link href={createPhoneLink(phone)}>{formatPhoneNumber(phone)}</Link>}

			{email && (
				<Paragraph asChild>
					<Link href={`mailto:${email}`}>{email}</Link>
				</Paragraph>
			)}
		</div>
	);
};
