import {groq} from 'next-sanity';

import type {ImageWebSchema} from '@/modules/foundation/sanity/image/schema';
import type {LinkFragment} from '@/modules/foundation/sanity/link/query';
import {LINK_FRAGMENT} from '@/modules/foundation/sanity/link/query';

import type {LinkListSelectionSchema} from './schema';

export const LINK_LIST_SELECTION_FRAGMENT = groq`
	...,
	links[]{
		...,
		${LINK_FRAGMENT}
	}
`;

export type LinkFragmentType = LinkFragment & {
	_key: string;
	title?: string;
	image?: ImageWebSchema;
};

export type LinkListSelectionFragmentType = Omit<LinkListSelectionSchema, 'links'> & {
	links?: LinkFragmentType[];
};
