import {groq} from 'next-sanity';

import {PAGE_HEADER_TYPE_HERO_WITH_BG_IMAGE} from '@/modules/page/sanity/page-head/hero-with-bg-image-container/constants';
import {
	HERO_WITH_BG_IMAGE_FRAGMENT,
	type HeroWithBgImageFragment,
} from '@/modules/page/sanity/page-head/hero-with-bg-image-container/query';
import {PAGE_HEADER_TYPE_HERO_WITH_SIDE_IMAGE} from '@/modules/page/sanity/page-head/hero-with-side-image-container/constants';
import {
	HERO_WITH_SIDE_IMAGE_FRAGMENT,
	type HeroWithSideImageFragment,
} from '@/modules/page/sanity/page-head/hero-with-side-image-container/query';
import {PAGE_HEADER_TYPE_TEXT_HEADING} from '@/modules/page/sanity/page-head/text-heading-container/constants';
import {
	TEXT_HEADING_FRAGMENT,
	type TextHeadingFragment,
} from '@/modules/page/sanity/page-head/text-heading-container/query';

export const PAGE_HEAD_FRAGMENT = groq`
	...,
	_type == "${PAGE_HEADER_TYPE_HERO_WITH_BG_IMAGE}" => {
		${HERO_WITH_BG_IMAGE_FRAGMENT}
	},
	_type == "${PAGE_HEADER_TYPE_HERO_WITH_SIDE_IMAGE}" => {
		${HERO_WITH_SIDE_IMAGE_FRAGMENT}
	},
	_type == "${PAGE_HEADER_TYPE_TEXT_HEADING}" => {
		${TEXT_HEADING_FRAGMENT}
	}
`;

export type PageHeadFragment =
	| TextHeadingFragment
	| HeroWithBgImageFragment
	| HeroWithSideImageFragment;
