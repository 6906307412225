import {groq} from 'next-sanity';

import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {HeroWithSideImageSchema} from './schema';

export const HERO_WITH_SIDE_IMAGE_FRAGMENT = groq`
	...,
	introduction[]{
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

// TODO: Should add the resolved portable text field to this type:
export type HeroWithSideImageFragment = HeroWithSideImageSchema;
