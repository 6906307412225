import {groq} from 'next-sanity';

import {LINK_FRAGMENT} from '../link/query';

import {CALL_TO_ACTION_LINE_FRAGMENT} from './call-to-action-line-container/query';

export const PORTABLE_TEXT_FRAGMENT = groq`
	...,
	markDefs[] {
		...,
		${LINK_FRAGMENT}
	},
	${CALL_TO_ACTION_LINE_FRAGMENT}
`;
