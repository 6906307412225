interface Props {
	color?: string;
	className?: string;
	size?: number;
}

export const ArrowRightIcon = ({color = '#000000', className, size}: Props) => {
	return (
		<svg
			width={size ? `${size}` : '1em'}
			height={size ? `${size}` : '1em'}
			viewBox="0 0 1024 1024"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				fill={color}
				d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z"
			></path>
		</svg>
	);
};
