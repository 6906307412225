import type {FC} from 'react';

import {ArticleListAllContainer} from '@/modules/article/sanity/article-list-all-container';
import {ArticleListSelectionContainer} from '@/modules/article/sanity/article-list-selection-container';
import {ContactFormSectionContainer} from '@/modules/contact-form/sanity/contact-form-section-container';
import {GalleryContainer} from '@/modules/page/sanity/content-blocks/gallery-container';
import {LinkListSelectionContainer} from '@/modules/page/sanity/content-blocks/link-list-selection-container';
import {TextContainer} from '@/modules/page/sanity/content-blocks/text-container';
import {TextWithImageSplitContainer} from '@/modules/page/sanity/content-blocks/text-with-image-split-container';
import {PersonListAllContainer} from '@/modules/person/sanity/person-list-all-container';
import {PersonListGroupContainer} from '@/modules/person/sanity/person-list-group-container';
import {PersonListSelectionContainer} from '@/modules/person/sanity/person-list-selection-container';
import type {SanityArray} from '@/sanity/lib/types';

import type {ContentBlockFragment} from './query';

interface Props {
	content: SanityArray<ContentBlockFragment>;
}

const ContentBlocks: FC<Props> = ({content}) => {
	const sections = content?.map((section) => {
		switch (section._type) {
			case 'textSection':
				return <TextContainer key={section._key} data={section} />;
			case 'textWithImageSplit':
				return <TextWithImageSplitContainer key={section._key} data={section} />;
			case 'gallery':
				return <GalleryContainer key={section._key} data={section} />;
			case 'linkListSelection':
				return <LinkListSelectionContainer key={section._key} data={section} />;
			case 'articleListSelection':
				return <ArticleListSelectionContainer key={section._key} data={section} />;
			case 'articleListAll':
				return <ArticleListAllContainer key={section._key} data={section} />;
			case 'personListSelection':
				return <PersonListSelectionContainer key={section._key} data={section} />;
			case 'personListAll':
				return <PersonListAllContainer key={section._key} data={section} />;
			case 'personListGroup':
				return <PersonListGroupContainer key={section._key} data={section} />;
			case 'contactFormSection':
				return <ContactFormSectionContainer key={section._key} data={section} />;
			default:
				return null;
		}
	});

	return <>{sections}</>;
};

export default ContentBlocks;
