import type {FC, PropsWithChildren} from 'react';
import React from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import ImageFill from '@/modules/foundation/components/image/ImageFill';
import type {ImageWeb} from '@/modules/foundation/components/image/types';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	imageWeb?: ImageWeb;
	heading?: string;
};

export const heroWithBgImagePortableTextOverrides: PortableTextOverrides = {
	callToActionLine: 'justify-center',
};

export const HeroWithBgImage: FC<Props> = ({children, imageWeb, heading}) => {
	return (
		<Container>
			{imageWeb && (
				<div className={clsx('-z-10')}>
					<ImageFill
						priority // Images in page heads are usually part of the LCP
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						height={imageWeb.image.height}
						hotspot={imageWeb.image.hotspot}
						alt={imageWeb.altText ?? ''}
					/>
				</div>
			)}
			<div
				className={clsx(
					'mx-auto',
					'max-w-3xl',
					'text-center',
					'pt-28',
					'pb-64',
					'md:pb-96',
				)}
			>
				{heading && <Heading spacing>{heading}</Heading>}

				{children}
			</div>
		</Container>
	);
};
