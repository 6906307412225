import {groq} from 'next-sanity';

import type {SanityArray} from '@/sanity/lib/types';

import type {LinkFragment} from '../../link/query';
import {LINK_FRAGMENT} from '../../link/query';

import type {CallToActionLineSchema} from './schema';
import type {CallToActionColorSchema} from './schema';

export const CALL_TO_ACTION_FRAGMENT = groq`
	...,
	${LINK_FRAGMENT}
`;

export type CallToActionFragment = Omit<LinkFragment, '_type'> & {
	_type: 'callToAction';
	text?: string;
	color?: CallToActionColorSchema;
};

export const CALL_TO_ACTION_LINE_FRAGMENT = groq`
	_type == "callToActionLine" => {
		...,
		callToActions[] {
			...,
			${CALL_TO_ACTION_FRAGMENT}
		}
	}
`;

export type CallToActionLineFragment = Omit<CallToActionLineSchema, 'callToActions'> & {
	callToActions?: SanityArray<CallToActionFragment>;
};
