import type {FC, PropsWithChildren} from 'react';

import {Container, type ContainerSpacing} from '@/modules/foundation/components/container';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';

type Props = PropsWithChildren & {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const textPortableTextOverrides: PortableTextOverrides = {};

export const Text: FC<Props> = ({children, spacingBottom, spacingTop}) => {
	return (
		<Container width="text" spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{children}
		</Container>
	);
};
