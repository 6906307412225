import {groq} from 'next-sanity';

import type {KeyedArray} from '@/modules/foundation/shared/types';

import type {Article} from '../../shared/types';
import type {ArticleInCardFragment} from '../query';
import {ARTICLE_IN_CARD_FRAGMENT, articleInCardFragmentToArticleDetails} from '../query';

import type {BLOCK_TYPE_ARTICLE_LIST_SELECTION} from './constants';
import type {ArticleListSelectionSchema} from './schema';

export const ARTICLE_LIST_SELECTION_FRAGMENT = groq`
	...,
	articles[]-> {
		${ARTICLE_IN_CARD_FRAGMENT},
	}
`;

export type ArticleListSelectionFragmentType = Omit<ArticleListSelectionSchema, 'articles'> & {
	_type: typeof BLOCK_TYPE_ARTICLE_LIST_SELECTION;
	articles?: (ArticleInCardFragment | null)[];
};

export const articleListFromArticleListSelectionSection = (
	section: ArticleListSelectionFragmentType,
): KeyedArray<Article> => {
	const articles: KeyedArray<Article> = [];

	if (!section.articles?.length) {
		return articles;
	}

	for (const articleInCard of section.articles) {
		if (articleInCard) {
			const article = articleInCardFragmentToArticleDetails(articleInCard);
			if (article) {
				articles.push({
					_key: articleInCard._id,
					...article,
				});
			}
		}
	}

	return articles;
};
