import type {FC} from 'react';

import type {PageHeadFragment} from '@/modules/foundation/sanity/page-head/query';
import {HeroWithBgImageContainer} from '@/modules/page/sanity/page-head/hero-with-bg-image-container';
import {HeroWithSideImageContainer} from '@/modules/page/sanity/page-head/hero-with-side-image-container';
import {TextHeadingContainer} from '@/modules/page/sanity/page-head/text-heading-container';
import type {SanityArray} from '@/sanity/lib/types';

interface Props {
	pageHeads: SanityArray<PageHeadFragment>;
}

const PageHead: FC<Props> = ({pageHeads}) => {
	if (!pageHeads.length) {
		return null;
	}

	const activePageHead = pageHeads[0];

	switch (activePageHead._type) {
		case 'textHeading':
			return <TextHeadingContainer data={activePageHead} />;
		case 'heroWithSideImage':
			return <HeroWithSideImageContainer data={activePageHead} />;
		case 'heroWithBgImage':
			return <HeroWithBgImageContainer data={activePageHead} />;
		default:
			return null;
	}
};

export default PageHead;
